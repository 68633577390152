import React from "react"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import {
  faSlidersH,
  faUndo,
  faFileChartLine,
  faSteeringWheel,
  faRoute,
  faRampLoading,
  faUserClock,
  faTruckRamp,
  faSkullCrossbones,
  faPeopleCarry,
  faLayerGroup,
  faMapMarked,
  faPeopleArrows,
  faClock,
  faUsersCrown,
  faAddressCard,
  faCars,
  faBarcodeRead,
  faDoorClosed,
  faRadar,
  faFileSignature,
  faAnalytics,
  faEnvelopeOpenText,
  faCameraPolaroid,
  faSatellite,
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../layouts/layout"
import SEO from "../layouts/seo"

const FeaturesPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title={t("navigation.features")} />
      <article className="article text--center">
        <header className="article-header">
          <h1 className="heading heading--md">{t("features.header")}</h1>
        </header>

        <ul className="features-list">
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faSlidersH} />
            </div>
            <div className="name">
              <Trans>features.item-1</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faUndo} />
            </div>
            <div className="name">
              <Trans>features.item-2</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faFileChartLine} />
            </div>
            <div className="name">
              <Trans>features.item-3</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faSteeringWheel} />
            </div>
            <div className="name">
              <Trans>features.item-4</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faRoute} />
            </div>
            <div className="name">
              <Trans>features.item-5</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faRampLoading} />
            </div>
            <div className="name">
              <Trans>features.item-6</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faUserClock} />
            </div>
            <div className="name">
              <Trans>features.item-7</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faTruckRamp} />
            </div>
            <div className="name">
              <Trans>features.item-8</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faSkullCrossbones} />
            </div>
            <div className="name">
              <Trans>features.item-9</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faPeopleCarry} />
            </div>
            <div className="name">
              <Trans>features.item-10</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faLayerGroup} />
            </div>
            <div className="name">
              <Trans>features.item-11</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faMapMarked} />
            </div>
            <div className="name">
              <Trans>features.item-12</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faPeopleArrows} />
            </div>
            <div className="name">
              <Trans>features.item-13</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faClock} />
            </div>
            <div className="name">
              <Trans>features.item-14</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faUsersCrown} />
            </div>
            <div className="name">
              <Trans>features.item-15</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faAddressCard} />
            </div>
            <div className="name">
              <Trans>features.item-16</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faCars} />
            </div>
            <div className="name">
              <Trans>features.item-17</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faBarcodeRead} />
            </div>
            <div className="name">
              <Trans>features.item-18</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faDoorClosed} />
            </div>
            <div className="name">
              <Trans>features.item-19</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faRadar} />
            </div>
            <div className="name">
              <Trans>features.item-20</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faFileSignature} />
            </div>
            <div className="name">
              <Trans>features.item-21</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faAnalytics} />
            </div>
            <div className="name">
              <Trans>features.item-22</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faEnvelopeOpenText} />
            </div>
            <div className="name">
              <Trans>features.item-23</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faCameraPolaroid} />
            </div>
            <div className="name">
              <Trans>features.item-24</Trans>
            </div>
          </li>
          <li>
            <div className="icon">
              <FontAwesomeIcon icon={faSatellite} />
            </div>
            <div className="name">
              <Trans>features.item-25</Trans>
            </div>
          </li>
        </ul>
      </article>
    </Layout>
  )
}
export default FeaturesPage
